import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const ContactPage = () => (

 <Layout>
   	<SEO title="CONTACT" />
   	<h1 style={{color:"green", textAlign:'center'}}>CONTACT</h1>

   	<a href="mailto:contact@thesteech.com">
   		<div style={{fontSize:'8rem', margin: '20px auto', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
   			<img src={`/../gifs/email.gif`}/>
   			<img src={`/../gifs/email.gif`}/>
   			<img src={`/../gifs/email.gif`}/>
   		</div>
   	</a>
   	<div style={{margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
   	   <img src={`/../gifs/campfire.gif`}/>         
   	</div>
</Layout>
)

export default ContactPage
